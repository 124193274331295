<template>
  <b-card>
    <baseform
      :fields="fields"
      :posturl="posturl"
      :title="title"
    />
  </b-card>
</template>

<script>
import baseform from '@/views/base/BaseForm.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    baseform,
    BCard,
  },
  data() {
    return {
      title:'Area',
      posturl:'/area',
      fields :[
        {
          type: 'input',
          label:'nama',
          key:'name',
          rules:'required'
        },
        // {
        //   type: 'input',
        //   label:'alamat',
        //   key:'alamat',
        // },
        // {
        //   type: 'input',
        //   label:'email',
        //   key:'email',
        //   rules:'email'
        // },
        // {
        //   type: 'number',
        //   label:'price',
        //   key:'price',
        //   rules:''
        // },
        // {
        //   type: 'text',
        //   label:'desc',
        //   key:'desc',
        //   rules:''
        // },
        // {
        //   type: 'date',
        //   label:'Tanggal Lahir',
        //   key:'tgl',
        //   rules:'required'
        // },
        // {
        //   type: 'select',
        //   label:'Jenis Kelamin',
        //   key:'gender',
        //   rules:'',
        //   options: [
        //     { value: 'a', text: 'This is First option' },
        //     { value: 'b', text: 'Simple Option' },
        //   ],
        // },
        // {
        //   type: 'bool',
        //   label:'active',
        //   key:'isactive',
        //   rules:''
        // },
      ],
    }
  },
}
</script>